@import '../_base/config';

.m-consent {
  align-items: center;
  background-color: white;
  border: 1px solid #c7cdd2;
  border-radius: 8px;
  display: flex;
  font-family: $font-family-base;
  font-size: 14px;
  margin-bottom: 30px;
  min-height: 70px;
  overflow: hidden;
  text-align: left;

  &__icon {
    align-items: center;
    align-self: normal;
    background-color: #f1f1f5;
    display: flex;
    padding: 10px 16px;

    svg {
      fill: #767676;
      height: 24px;
      width: 24px;
    }
  }

  &__content {
    font-weight: bold;
    padding: 10px;
    background-color: #fff;

    &--text {
      margin: 0 0 5px;
      color: $a11y-gray-light;
    }

    &--link {
      color: $blue;
      text-decoration: none;

      &:hover {
        color: $blue-hover;
      }
    }
  }
}
