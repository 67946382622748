/**
 * NOTE:
 *   Previous @font-face rules on aol.com were implemented incorrectly.
 *   Since many styles throughout the site rely on the incorrect implementation,
 *   this is a namespaced copy of OpenSans for use in newer migrated modules.
 *
 * DETAILS:
 *   - all OpenSans @font-face rules should have the same `font-family` name
 *     even though they point to different files (bold, italic, bold-italic)
 *   - setting `font-weight` and `font-style` correctly within `@font-face`
 *     means the browser will choose the appropriate file according to the HTML
 *     element's `font-weight` and `font-style`
 *   - file-formats used in `src` have also been reduced to just `woff` in line
 *     with https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
 *
 * TODO: Have we licenced Larsseit-Thin, Larsseit-Light or Larsseit-ExtraBold?
 */

@font-face {
  font-display: swap;
  // default
  font-family: '_OpenSans';
  font-style: normal;
  src:
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/opensans/VF/OpenSans-VF.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/opensans/VF/OpenSans-VF.woff') format('woff')
}

@font-face {
  font-display: swap;
  // italic
  font-family: '_OpenSans';
  font-style: italic;
  src:
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/opensans/VF/OpenSans-Italic-VF.woff2') format('woff2'),
    url('https://s.yimg.com/cv/apiv2/aolfp/fonts/opensans/VF/OpenSans-Italic-VF.woff') format('woff')
}


body {
  font-family: '_OpenSans', 'OpenSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

input,
textarea,
select {
  font-family: inherit;
}
