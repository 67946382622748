@import "../_base/config";

// SCSS vars must be wrapped in #{} to be declared as CSS3 vars
// See: https://sass-lang.com/documentation/breaking-changes/css-vars

:root{
  --mail-bgc: #{$gray-bg-mail};
  --text-base: #{$batcave};
  --text-gray: #{$dolphin};
  --accent-color: #{$dory};
  --hover-color: #{$dory-hover};
  --bg-accent: #{$white};
  --bg-accent-dark: #{$blue-65};
  --mail-border-color: #{$white};
  --mail-bg-new: #{$white};
  --mail-bg-hover:#{$marshmallow};
  --mail-border-style:none;
}
