.heading-3 {
  font-family: $font-family-sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.235;
}

.heading-4 {
  font-family: $font-family-sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.267;
}

.content-1 {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

.content-2 {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
}

.content-3 {
  font-family: $font-family-sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 1.4;
}

.content-4 {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1;
  text-transform: uppercase;
}
